import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { usePageRedirection } from './utils/hooks/usePageRedirection';
import './App.css';

import Loader from './components/Loader';
const Home = lazy(() => import('./tabs/home/Home'));
const IncomingProjects = lazy(() => import('./tabs/incomingProjects/IncomingProjects'));
const Contact = lazy(() => import('./tabs/contact/Contact'));
const Projekti = lazy(() => import('./tabs/projects/Projekti'));

export const history = createBrowserHistory();

export default function App() {
    const { aboutRef, goToPageSection } = usePageRedirection();
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    // This function will scroll the window to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // for smoothly scrolling
        });
    };
    return (
        <>
            {showButton && (
                <button onClick={scrollToTop} className='toTopBtn'>
                    <i className='fas fa-arrow-up'></i>
                </button>
            )}
            <Suspense fallback={<Loader />}>
                <Router history={history}>
                    <Route exact path='/' render={() => <Home aboutRef={aboutRef} />} />
                    <Route path='/projekti_u_izgradnji/*' render={() => <IncomingProjects />} />
                    <Route path='/projekti' component={Projekti} />
                    <Route path='/kontakt' component={Contact} />
                </Router>
            </Suspense>
        </>
    );
}
